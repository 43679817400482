import React from "react";
import {
  Container,
  Title,
  Content,
  WrapperFunctions,
  Function,
  Icon,
  TitleFunction,
  Description,
  Image,
} from "./styles";
import iconLocation from "../../../assets/icons/iconLocation.webp";
import iconProfile from "../../../assets/icons/iconProfile.webp";
import iconReward from "../../../assets/icons/iconReward.webp";
import iconSelfie from "../../../assets/icons/iconSelfie.webp";

export default function SectionFunction() {
  return (
    <Container>
      <Title>
        TENHA O MUNDO EM SUAS MÃOS. FOTOGRAFE, CRIE VÍDEOS E PUBLIQUE
      </Title>
      <Content>
        <WrapperFunctions>
          <Function>
            <Icon>
              <Image src={iconLocation} alt="Ícone de localização" />
            </Icon>
            <TitleFunction>Registre Momentos</TitleFunction>
            <Description>
              Espalhe sua cultura, suas ideias e seja visto. Seja grato hoje e
              torne outros gratos por suas ideias.
            </Description>
          </Function>

          <Function>
            <Icon>
              <Image src={iconProfile} alt="Ícone de perfil" />
            </Icon>
            <TitleFunction>Social</TitleFunction>
            <Description>
              Veja pessoas, conheça lugares. Sem a necessidade de seguir alguém
              ou lugar.
            </Description>
          </Function>

          <Function>
            <Icon>
              <Image src={iconReward} alt="Ícone de recompensa (uma medalha)" />
            </Icon>
            <TitleFunction>Descubra novos lugares</TitleFunction>
            <Description>
              Veja fotos reais dos locais. Descubra o que cada cidade pode
              oferecer. Lugares, eventos, música e cultura local.
            </Description>
          </Function>

          <Function>
            <Icon>
              <Image src={iconSelfie} alt="Ícone de uma camera tirando foto" />
            </Icon>
            <TitleFunction>Receba recompensa</TitleFunction>
            <Description>
              Receba recompensas usando nosso aplicativo e convide novos amigos
              para compartilhar.
            </Description>
          </Function>
        </WrapperFunctions>
      </Content>
    </Container>
  );
}
