import React, { useRef } from "react";
import SectionFunction from "./sectionFunctionApp/";
import SectionHero from "./sectionHero";
import Header from "../../components/header";
import AboutUs from "./sectionAboutUs";
import ImagesApresentation from "./sectionApresentation";
import EarnMoney from "./sectionMeet";
import RegisterSection from "./sectionRegister";
import SectionContact from "./sectionContact";
import SectionDownload from "./sectionDownload";

const Home = () => {
  const homeReference = useRef(null);
  const aboutReference = useRef(null);
  const contactReference = useRef(null);

  const handleScrollToRef = (where) => {
    if (where === "home") {
      if (homeReference.current) {
        homeReference.current.scrollIntoView({
          behavior: "smooth",
        });
      }
      return;
    }

    if (where === "about") {
      if (aboutReference.current) {
        aboutReference.current.scrollIntoView({
          behavior: "smooth",
        });
      }
      return;
    }

    if (where === "contact") {
      if (contactReference.current) {
        contactReference.current.scrollIntoView({
          behavior: "smooth",
        });
      }
      return;
    }
  };

  return (
    <>
      <Header handleClick={handleScrollToRef} />
      <SectionHero reference={homeReference} />
      <SectionFunction />
      <AboutUs reference={aboutReference} />
      <ImagesApresentation />
      <EarnMoney />
      <RegisterSection />
      <SectionContact reference={contactReference} />
      <SectionDownload />
      <Header copyright handleClick={handleScrollToRef} />
    </>
  );
};

export default Home;
