import styled from "styled-components";

export const Container = styled.section`
  margin-top: 204px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 203px;

  @media (max-width: 723px) {
    margin-top: 40px;
    margin-bottom: 40px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: #c4c4c450;
    padding-bottom: 24px;
  }
`;

export const Title = styled.h1`
  width: 722.9px;
  margin-bottom: 121px;
  text-align: center;
  font-family: "outfit", sans-serif;
  font-size: 30px;
  font-weight: 800;
  color: #2c272e;
  opacity: 0.7;
  letter-spacing: -2.5px;

  @media (max-width: 723px) {
    width: 100%;
    margin-bottom: 0px;
  }
`;

export const Content = styled.div`
  width: 1293px;
  justify-content: center;
  align-items: center;
  display: flex;

  @media (max-width: 1293px) {
    width: 100%;
  }
`;

export const WrapperFunctions = styled.ul`
  list-style-type: none;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;

  @media (max-width: 1067px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    place-items: center;
  }
  @media (max-width: 601px) {
    flex-direction: column;
    display: flex;
    margin-top: 50px;
    vertical-align: middle;
  }
`;

export const Function = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 300px;
  height: 153px;
`;

export const Icon = styled.div`
  width: 46px;
  height: 46px;
  background: #f6815c;
  border-radius: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleFunction = styled.h3`
  width: 100%;
  height: 21px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.title};
  font-size: 14px;
  font-weight: 600;
`;

export const Description = styled.p`
  width: 264px;
  height: 54px;
  font-family: "outfit", sans-serif;
  font-size: 12px;
  justify-content: center;
  display: flex;
  color: #868e98;
  line-height: 16px;
`;

export const Image = styled.img`
  width: 34px;
  height: 34px;
`;
