import React from "react";
import {
  Container,
  Content,
  WrapperAppImages,
  Meet,
  Background,
  AppEarnMoney,
  Subtitle,
  Title,
  Choice,
  ListBenefits,
  CheckIcon,
  Benefits,
} from "./style";
import background from "../../../assets/backgroundEarnMoney.webp";
import appEarnMoney from "../../../assets/appEarnMoney.png";
import iconCheck from "../../../assets/icons/iconCheck.webp";

export default function EarnMoney() {
  return (
    <Container>
      <Content>
        <Meet>
          <Subtitle>CONHEÇA</Subtitle>
          <Title>Ganhe moedas fotografando, gravando vídeos e publique</Title>
          <Choice>Escolha sua cultura, suas idéias</Choice>
          <ListBenefits>
            <Benefits>
              <CheckIcon src={iconCheck} alt="Icone de checado" /> Tenha contato
              com o mundo, e veja eventos da sua cidade
            </Benefits>
            <Benefits>
              <CheckIcon src={iconCheck} alt="Icone de checado" /> Trabalho
              cultural de cidades e suas maravilhas
            </Benefits>
            <Benefits>
              <CheckIcon src={iconCheck} alt="Icone de checado" /> Faça sua
              cidade ser vista por usuários de qualquer lugar
            </Benefits>
          </ListBenefits>
        </Meet>
      </Content>

      <WrapperAppImages>
        <Background src={background} alt="Uma cor de fundo alaranjada" />
        <AppEarnMoney
          src={appEarnMoney}
          alt="Três telas do app uma ao lado da outra"
        />
      </WrapperAppImages>
    </Container>
  );
}
