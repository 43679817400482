import styled from "styled-components";

export const Container = styled.section`
  height: 623px;
  margin-top: 159px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 270px;
  gap: 60px;

  @media (max-width: 1461px) {
    margin-bottom: 100px;
  }
`;

export const Drink = styled.img`
  width: 601px;
  height: 623px;
  @media (max-width: 1135px) {
    display: none;
  }
`;

export const App = styled.img`
  width: 456px;
  height: 623px;
  @media (max-width: 475px) {
    width: 100%;
    height: 100%;
  }
`;
