/* eslint-disable import/no-anonymous-default-export */
import { css } from "styled-components";

export const fontFaces = css`
  @font-face {
    font-family: "outfit";
    src: url("https://fonts.googleapis.com/css2?family=Outfit&display=swap")
      format("woff2");
    font-weight: 400;
    font-style: normal;
  }
`;

export default {
  colors: {
    primary: "#F6815C",
    secondary: "#F5EBE6",
    title: "#2C272E",
  },
  fonts: {
    outfit: fontFaces,
  },
};
