import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;

  @media (max-width: 613px) {
    flex-direction: column;
  }
`;

export const WrapperLogo = styled.div`
  max-width: 601px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 613px) {
    display: none;
  }
`;

export const Figure = styled.figure`
  width: 267px;
  height: 214.97px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Logo = styled.img`
  width: 79.18px;
  height: 113.12px;
`;

export const LogoText = styled.label`
  font-size: 69.4132px;
  color: #76777e;
  font-family: "outfit", sans-serif;
  font-weight: 700;
`;

export const Content = styled.div`
  width: 449px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 491px) {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-family: "outfit", sans-serif;
  font-weight: 800;
  font-size: 18px;
`;

export const Title = styled.h1`
  font-family: "outfit", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 35px;
  width: 100%;
  line-height: 52px;
  color: ${({ theme }) => theme.colors.title};
  @media (max-width: 491px) {
    text-align: center;
  }
`;

export const Feedback = styled.p`
  font-family: "outfit", sans-serif;
  color: #76777e;
  font-size: 16px;
`;

export const WrapperStars = styled.figure`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;

export const Rate = styled.img`
  width: 136px;
  height: 24px;
`;

export const Note = styled.label`
  font-size: 20px;
  font-family: "outfit", sans-serif;
  font-weight: bold;
  color: #76777e;
  margin-left: 15px;
`;
