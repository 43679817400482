import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  margin-top: 124px;
  padding: 80px 0;
`;

export const Content = styled.div`
  width: 70%;
  display: flex;

  @media (max-width: 1171px) {
    width: 80%;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 915px) {
    width: 90%;
  }
  @media (max-width: 797px) {
    width: 100%;
    flex-direction: column;
    height: 100%;
  }
`;

export const WrapperSendContact = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 341px) {
    width: 100%;
  }
  @media (max-width: 704px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

export const ImageContact = styled.img`
  width: 337px;
  height: 346px;
`;

export const IconBoxMessage = styled.img`
  position: absolute;
  margin-right: 355px;
  margin-bottom: 250px;
  @media (max-width: 435px) {
    display: none;
  }
  width: 72.18px;
  height: 72.18px;
`;

export const WrapperQuestions = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media (max-width: 797px) {
    margin-top: 20px;
    width: 70%;
    text-align: center;
  }
  @media (max-width: 443px) {
    width: 90%;
  }
  @media (max-width: 353px) {
    width: 100%;
  }
  @media (max-width: 704px) {
    margin-bottom: 50px;
  }
`;

export const Subtitle = styled.label`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 18px;
  font-weight: bold;
  font-family: "outfit", sans-serif;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.title};
  font-size: 28px;
  font-weight: bold;
  font-family: "outfit", sans-serif;
  margin-bottom: 45px;
  margin-top: 13.5px;
`;
