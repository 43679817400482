import styled from "styled-components";

export const Container = styled.header`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

export const HeaderPage = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
  max-width: 1041px;
`;

export const ContainerLogo = styled.figure`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-left: 10px;
  gap: 10px;

  @media (max-width: 592px) {
    padding: 0px;
  }
`;

export const Logo = styled.img`
  width: 159px;
  height: 40px;

  @media (max-width: 592px) {
    width: ${({ hasCopyright }) => (hasCopyright ? "79.5px" : "159px")};
    height: ${({ hasCopyright }) => (hasCopyright ? "20px" : "40px")};
  }
`;

export const ContainerNavigation = styled.ul`
  list-style-type: none;
  display: flex;
  gap: 40px;
  align-items: center;

  @media (max-width: 592px) {
    gap: 20px;
  }
`;

export const MenuItem = styled.li`
  font-family: "outfit", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.title};
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Copyright = styled.label`
  color: #575757;
  font-size: 10px;
  font-family: "outfit", sans-serif;
  font-weight: 600;
`;
