import styled from "styled-components";

export const Container = styled.section`
  height: 740px;
  max-width: 100%;
  width: 1346px;
  display: flex;
  flex-direction: row;
  margin: 0 auto 124px;
  @media (max-width: 1461px) {
    height: 400px;
  }
`;

export const Content = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1461px) {
    width: 100%;
  }
`;

export const WrapperAppImages = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1461px) {
    display: none;
  }
`;

export const Meet = styled.div`
  width: 541px;
  height: 329px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  @media (max-width: 672px) {
    align-items: center;
    justify-content: center;
    width: 80%;
  }
`;

export const Background = styled.img`
  @media (max-width: 1461px) {
    display: none;
  }
  width: 548px;
  height: 740px;
`;

export const AppEarnMoney = styled.img`
  position: absolute;
  @media (max-width: 1461px) {
    display: none;
  }
  width: 774px;
  height: 543.92px;
`;

export const Subtitle = styled.label`
  color: ${({ theme }) => theme.colors.primary};
  font-family: "outfit", sans-serif;
  font-weight: bold;
`;

export const Title = styled.h1`
  font-family: "outfit", sans-serif;
  color: #0e1e31;
  font-size: 28px;
  width: 470px;
  @media (max-width: 672px) {
    text-align: center;
  }
  font-weight: 700;
  @media (max-width: 475px) {
    width: 100%;
  }
`;

export const Choice = styled.p`
  font-family: "outfit", sans-serif;
  color: #7b747e;
  font-size: 16px;
`;

export const ListBenefits = styled.ul`
  width: 541px;
  display: flex;
  flex-direction: column;
  height: 108px;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 672px) {
    width: 100%;
    margin-top: 20px;
    height: 150px;
  }
  gap: 10px;
  margin-top: 54px;
`;

export const Benefits = styled.li`
  font-family: "Lato", sans-serif;
  color: ${({ theme }) => theme.colors.title};
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 5px;
  font-weight: 400;
`;

export const CheckIcon = styled.img`
  width: 20px;
  height: 20px;
`;
