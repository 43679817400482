import React from "react";
import { Container, Drink, App } from "./styles";
import imageDrink from "../../../assets/imageDrink.webp";
import imageCellphone from "../../../assets/imageCellphone.webp";

export default function ImagesApresentation() {
  return (
    <Container>
      <Drink src={imageDrink} alt="Imagem de um drink em uma paisagem" />
      <App src={imageCellphone} alt="Feed do app begrato com os posts" />
    </Container>
  );
}
