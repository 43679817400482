import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const Selfie = styled.section`
  width: 100%;
  width: 1380px;
  height: 744px;
  display: flex;
  flex-direction: row;
`;

export const StarterPack = styled.div`
  width: 875px;
  height: 740px;
  background: #f5ebe6;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 1380px) {
    width: 100%;
    border-radius: 0px;
  }
`;
export const Content = styled.div`
  width: 470px;
  height: 329px;
  margin-right: 150px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media (max-width: 1380px) {
    margin: 0 auto;
  }
  @media (max-width: 515px) {
    width: 100%;
    height: 50%;
    text-align: center;
    margin: 0px 20px;
  }
`;

export const TitleStarter = styled.label`
  font-family: "outfit", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 2px;
  color: ${({ theme }) => theme.colors.primary};
`;
export const AppSelfieWrite = styled.h1`
  font-family: "outfit", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  color: ${({ theme }) => theme.colors.title};
`;

export const SmartNetwork = styled.p`
  font-family: "outfit", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #797979;
`;

export const ContainerStores = styled.figure`
  display: flex;
  flex-direction: row;
  gap: 10px;
  @media (max-width: 515px) {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 389px) {
    flex-direction: column;
  }
`;

export const AppStore = styled.img`
  width: 182.68px;
  height: 54px;
  cursor: pointer;
`;

export const GooglePlay = styled.img`
  width: 182.68px;
  height: 54px;
  cursor: pointer;
`;

export const ContainerGooglePLay = styled.figure`
  width: 182.68px;
  height: 54px;
  background: #000000;
  border: 1.3px solid #aeafaf;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SmileCar = styled.div`
  width: 505px;
  justify-content: flex-end;
  display: flex;
  @media (max-width: 1380px) {
    display: none;
  }
`;

export const ImageSmile = styled.img`
  width: 475px;
  height: 600px;
`;

export const Iphone = styled.img`
  margin-left: 112px;
  width: 402px;
  height: 662px;
`;

export const ContainerIphone = styled.figure`
  width: 401.81px;
  height: 100%;
  position: relative;
  margin-top: 80px;
`;
