import React from "react";
import {
  Container,
  Logo,
  MenuItem,
  HeaderPage,
  ContainerLogo,
  ContainerNavigation,
  Copyright,
} from "./styles";
import BegratoLogo from "../../assets/Logo.webp";

export default function Header({ handleClick, copyright }) {
  const handleScrollToReference = (where) => {
    handleClick(where);
  };

  const today = new Date();
  const year = today.getFullYear();

  return (
    <Container>
      <HeaderPage>
        <ContainerLogo>
          <Logo hasCopyright={copyright} src={BegratoLogo} alt="logo Begrato" />
          {copyright && <Copyright>Copyright &copy; {year}</Copyright>}
        </ContainerLogo>

        <ContainerNavigation>
          <MenuItem onClick={() => handleScrollToReference("home")}>
            Home
          </MenuItem>
          <MenuItem onClick={() => handleScrollToReference("about")}>
            Sobre nós
          </MenuItem>
          <MenuItem onClick={() => handleScrollToReference("contact")}>
            Contato
          </MenuItem>
        </ContainerNavigation>
      </HeaderPage>
    </Container>
  );
}
