import React from "react";
import {
  Container,
  Selfie,
  StarterPack,
  SmileCar,
  ImageSmile,
  TitleStarter,
  Content,
  AppSelfieWrite,
  SmartNetwork,
  ContainerStores,
  AppStore,
  GooglePlay,
  ContainerGooglePLay,
  Iphone,
  ContainerIphone,
} from "./styles";
import smileCar from "../../../assets/smileCar.webp";
import appStore from "../../../assets/appStore.webp";
import googlePlay from "../../../assets/googlePlay.webp";
import iphone from "../../../assets/MockupApp.webp";
import { motion } from "framer-motion";

const SectionHero = ({ reference }) => {
  return (
    <Container ref={reference}>
      <Selfie>
        <StarterPack>
          <Content>
            <TitleStarter>STARTER PACK</TitleStarter>
            <AppSelfieWrite>
              Aplicativo de fotos e vídeos inteligente
            </AppSelfieWrite>
            <SmartNetwork>
              Rede inteligente de turismo onde o viajante tem uma experiência
              real de lugares e acontecimentos.
            </SmartNetwork>
            <ContainerStores>
              <AppStore src={appStore} alt="Icone para download na AppStore" />
              <ContainerGooglePLay>
                <GooglePlay
                  src={googlePlay}
                  alt="Icone para download no GooglePlay"
                />
              </ContainerGooglePLay>
            </ContainerStores>
          </Content>
        </StarterPack>
        <SmileCar>
          <ContainerIphone>
            <Iphone
              src={iphone}
              alt="Um celular com o app iniciado mostrando o feed da begrato"
            />
          </ContainerIphone>
          <ImageSmile
            src={smileCar}
            alt="Duas pessoas numa estrada sorrindo numa viagem"
          />
        </SmileCar>
      </Selfie>
    </Container>
  );
};

export default SectionHero;
