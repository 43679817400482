import React from "react";
import { ButtonPage } from "./styles";

export default function Button({ width, height, children, style }) {
  return (
    <ButtonPage width={width} height={height} style={style}>
      {children}
    </ButtonPage>
  );
}
