import React from "react";
import {
  Container,
  WrapperLogo,
  Logo,
  LogoText,
  Title,
  Subtitle,
  Content,
  Figure,
  Feedback,
  WrapperStars,
  Rate,
  Note,
} from "./styles";
import logoBegrato from "../../../assets/icons/iconBegrato.webp";
import iconStars from "../../../assets/icons/iconStars.webp";

export default function AboutUs({ reference }) {
  return (
    <Container ref={reference}>
      <WrapperLogo>
        <Figure>
          <Logo src={logoBegrato} alt="logoBegrato" />
          <LogoText>Begrato</LogoText>
        </Figure>
      </WrapperLogo>

      <Content>
        <Subtitle>SOBRE NÓS</Subtitle>
        <Title>Descubra novos lugares, novas possibilidades</Title>
        <Feedback>Avaliação dos usuários</Feedback>
        <WrapperStars>
          <Rate src={iconStars} alt="5 estrelas de avaliação" />
          <Note>4,3 de 5</Note>
        </WrapperStars>
      </Content>
    </Container>
  );
}
