import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: 369px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const Content = styled.div`
  width: 619px;
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 559px) {
    width: 100%;
    height: 369px;
  }
`;

export const Subtitle = styled.label`
  font-size: 14px;
  font-family: "outfit", sans-serif;
  font-weight: bold;
  color: #ffffff;
`;

export const Title = styled.h1`
  font-size: 42px;
  font-family: "outfit", sans-serif;
  font-weight: bold;
  color: #ffffff;
  @media (max-width: 501px) {
    font-size: 30px;
  }
  @media (max-width: 365px) {
    font-size: 27px;
  }
`;

export const Description = styled.p`
  font-size: 16px;
  font-family: "outfit", sans-serif;
  color: #ffffff;
  text-align: center;
  line-height: 20px;

  @media (max-width: 559px) {
    width: 90%;
  }
`;

export const WrapperDownload = styled.figure`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  margin-top: 38px;
`;

export const IconAppStore = styled.img`
  width: 171px;
  height: 54px;
  @media (max-width: 381px) {
    width: 150px;
  }
  cursor: pointer;
`;
export const IconGooglePlay = styled.img`
  width: 171px;
  height: 54px;
  @media (max-width: 381px) {
    width: 150px;
    height: 44px;
  }
  cursor: pointer;
`;
