import styled from "styled-components";

export const ButtonPage = styled.button`
  font-family: "outfit", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.primary};
  width: ${({ width }) => width + "px"};
  height: ${({ height }) => height + "px"};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
`;
