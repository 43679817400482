import React from "react";
import {
  Container,
  Subtitle,
  Title,
  Description,
  WrapperDownload,
  IconAppStore,
  IconGooglePlay,
  Content,
} from "./style";

import GooglePlay from "../../../assets/googlePlay.webp";
import AppStore from "../../../assets/appStore.webp";

export default function SectionDownload() {
  return (
    <Container>
      <Content>
        <Subtitle>FAÇA DOWNLOAD</Subtitle>
        <Title>Descubra novos lugares</Title>
        <Description>
          Descubra outras cidades navegando com o seu celular, em cada cidade
          você pode ver as maravilhas que existem, espalhar a palavra sobre o
          que você faz, o que você gosta e garante que os outros possam vê-lo.
        </Description>
        <WrapperDownload>
          <IconAppStore
            src={AppStore}
            alt="Icone para download do na AppStore"
          />
          <IconGooglePlay
            src={GooglePlay}
            alt="Icone para download no GooglePlay"
          />
        </WrapperDownload>
      </Content>
    </Container>
  );
}
