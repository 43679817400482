import React from "react";
import {
  Container,
  Content,
  WrapperSendContact,
  WrapperQuestions,
  ImageContact,
  IconBoxMessage,
  Subtitle,
  Title,
} from "./style";
import sendContact from "../../../assets/imageContact.webp";
import Button from "../../../components/button";
import boxMessage from "../../../assets/boxMessage.webp";
import Accordion from "../../../components/accordion";

export default function SectionContact({ reference }) {
  return (
    <Container ref={reference}>
      <Content>
        <WrapperSendContact>
          <IconBoxMessage
            src={boxMessage}
            alt="icone de correio para envio de mensagens"
          />
          <ImageContact
            src={sendContact}
            alt="Uma pessoa utilizando um tablet na mão"
          />
          <Button width={337} height={48} style={{ fontWeight: 600 }}>
            Entrar em Contato
          </Button>
        </WrapperSendContact>
        <WrapperQuestions>
          <Subtitle>CONTATO</Subtitle>
          <Title>Perguntas Frequentes</Title>
          <Accordion
            title="Funcionalidades do aplicativo Begrato"
            content="Registre momentos incríveis, espalhe cultura e ideias, receba
            recompensas, confira avaliações de usuários e tenha contato com
            eventos culturais locais."
          />
          <Accordion
            title="Experiência de turismo proporcionada pelo Begrato"
            content="Conheça novos lugares e culturas, descubra as maravilhas de
            diferentes cidades, tenha acesso a fotos reais de locais e eventos,
            e encontre lugares indicados na plataforma."
          />
          <Accordion
            title="Benefícios do uso do Begrato"
            content="Ganhe moedas fotografando e gravando vídeos, tenha contato com o
            mundo e veja eventos da sua cidade, e faça sua cidade ser vista por
            usuários de qualquer lugar."
          />
        </WrapperQuestions>
      </Content>
    </Container>
  );
}
