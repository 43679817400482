import React from "react";
import {
  Container,
  Content,
  WrapperAppImages,
  WrapperRegister,
  Background,
  AppRegister,
  Subtitle,
  Title,
  Choice,
  ListBenefits,
  CheckIcon,
  Benefits,
} from "./style";
import background from "../../../assets/backgroundEarnMoney.webp";
import appRegister from "../../../assets/appRegister.png";
import iconCheck from "../../../assets/icons/iconCheck.webp";

export default function RegisterSection() {
  return (
    <Container>
      <WrapperAppImages>
        <Background src={background} alt="Uma cor de fundo alaranjada" />
        <AppRegister
          src={appRegister}
          alt="Três telas mostrando a camera tirando fotos e registrando no app"
        />
      </WrapperAppImages>

      <Content>
        <WrapperRegister>
          <Subtitle>REGISTRE</Subtitle>
          <Title>Registre cada momento no seu aplicativo begrato</Title>
          <Choice>Escolha sua cultura, suas idéias</Choice>
          <ListBenefits>
            <Benefits>
              <CheckIcon src={iconCheck} alt="Icone de checado" /> Registre
              lugares incriveis da sua viajem ou cidade
            </Benefits>
            <Benefits>
              <CheckIcon src={iconCheck} alt="Icone de checado" /> Conheça
              outros lugares indicados na plataforma
            </Benefits>
            <Benefits>
              <CheckIcon src={iconCheck} alt="Icone de checado" /> Tenha uma
              prévia do que está acontecendo no mundo
            </Benefits>
          </ListBenefits>
        </WrapperRegister>
      </Content>
    </Container>
  );
}
