import styled from "styled-components";

export const AccordionWrapper = styled.div`
  border-bottom: 1px solid #d3d2d1;
  margin-bottom: 23.5px;
`;

export const AccordionHeader = styled.div`
  display: flex;
  text-align: start;
  align-items: center;
  padding: 10px;
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.title};
  height: 50px;
  font-family: "Outfit", sans-serif;
  cursor: pointer;
`;

export const AccordionContent = styled.div`
  padding: 0 10px;
  letter-spacing: 0.6px;
  line-height: 20px;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  font-family: "Outfit", sans-serif;
  color: #7b747e;
  margin-bottom: 16px;
`;
